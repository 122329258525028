/*!
 * Bootstrap Reboot v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
@at-root {
    @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

//
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';

//@import 'resets/bootstrap-colour-reset';


@import '../../../../node_modules/bootstrap/scss/mixins';
@import '../../../../node_modules/bootstrap/scss/root';
@import '../../../../node_modules/bootstrap/scss/reboot';
@import '../../../../node_modules/bootstrap/scss/type';
//@import '../../../../node_modules/bootstrap/scss/images';
@import '../../../../node_modules/bootstrap/scss/grid';
@import '../../../../node_modules/bootstrap/scss/utilities';
