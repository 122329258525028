

@function calculateRem($pixels, $context: $browser-context) {
    @return #{($pixels/$context)}rem;
}

@function calculateEm($pixels, $context: $browser-context) {
    @return #{($pixels/$context)}em;
}

@mixin fontSize($size) {
    font-size: calculateRem($size);
}

@mixin lineHeight($size) {
    line-height: calculateRem($size);
}

/*
Helper to return font size and lineheight
 */
@mixin font($fontSize, $lineHeight){
    @include fontSize($fontSize);
    @include lineHeight($lineHeight);
}
