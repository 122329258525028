/**
News landing template
 */

.news-landing-page {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();

    .promo-tile--small {
        padding-bottom: 50px;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        .promo-tile--small {
            padding-bottom: 60px;
        }
    }
}
