/**
Documentation
 */

.pay-my-bill-results {
    /**
    Default, Mobile first styles
     */
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: map_get($colours, 'n-9');


    .content-wrap {
        display: flex;
        justify-content: space-around;
    }

    .content {
        display: inline-block;
        background: white;
        padding: 40px;
    }
    
    //992 +
    @include media-breakpoint-up(lg) {
        
    }
}
