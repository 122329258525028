/**
Documentation
 */

.promo-tile-row {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin: 60px 0;

    .promo-tile--small,
    .promo-tile--medium,
    .promo-tile--large {
        height: 100%;
    }

    .row-title {
        @include fontSize(30px);
        @include lineHeight(38px);
        color: map_get($colours, 'p-1');
        font-weight: 700;
        margin-bottom: 40px;
    }

    /*
    Note: swiper isn't always present
    .promo-tile-row class (and styles) are in use for medium, large and small promo tiles
    however, swiper is only available for small
     */
    .swiper-container {
        padding: 0 $grid-gutter-width / 2;
        padding-bottom: 50px;
    }

    .tabbed-pagination {
        position: absolute;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        .row-title {
            @include fontSize(46px);
            @include lineHeight(56px);
            margin-bottom: 60px;
        }

        .swiper-container {
            padding-bottom: 0;
        }

        .tabbed-pagination {
            display: none;
        }
    }

}
