/**
Accordion module
Consists primarily of a button element, and a content block
 */
.accordion-module  {
    /*
    Module specific variables
     */
    $itemBorderColour: map_get($colours, "n-8");
    $btnTxtColour: map_get($colours, "n-2");
    $btnTxtColour_hover: map_get($colours, "n-3");


    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    width: 100%;
    margin-top: 80px;
    margin-bottom: 120px;

    .module-title {
        @include fontSize(26px);
        @include lineHeight(36px);
        color: map_get($colours, "n-2");
        font-weight: 700;
        margin-bottom: 24px;
    }


    .accordion-item {
        width: 100%;
        padding: 20px 0;
        position: relative;
        border-bottom: solid 1px $itemBorderColour;
    }

    .btn-accordion-trigger {
        @include btnReset();
        display: flex;
        width: 100%;
        color: $btnTxtColour;
        font-weight: 700;

        .title-text {
            @include fontSize(18px);
            @include lineHeight(27px);
            margin: 0;
            padding: 0;
        }

        .icon {
            margin-left: auto;
            align-self: center;
            opacity: 0.3;
        }

        .icon.active {
            display: none;
        }

        &:hover {
            color: $btnTxtColour_hover
        }
    }

    .accordion-content {
        @include animateCustom(#{'height'}, 250ms);
        overflow: hidden;
        padding-right: 30px;

        p {
            @include fontSize(16px);
            @include lineHeight(24px);
            color: map_get($colours, 'n-4');
            padding-top: 22px;
            margin: 0;
        }
    }

    /*
    Open accordion state
     */
    .accordion-item.active {

        .btn-accordion-trigger {

            .icon.default {
                display: none;
            }

            .icon.active {
                display: block;
                color: $btnTxtColour_hover;
                opacity: 1;
            }

            &:hover {
                color: $btnTxtColour_hover
            }
        }
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 80px;

        .module-title {
            p {
                @include fontSize(24px);
                @include lineHeight(34px);
            }
        }

        .btn-accordion-trigger {
            .title-text {
                @include fontSize(18px);
                @include lineHeight(27px);
            }
        }

        .accordion-content {
            @include animateCustom(#{'height'}, 250ms);
            overflow: hidden;
            padding-left: 20px;
            padding-right: 65px;
        }
    }
}
