/**
CSS overrides for Weatherzone widget
Note: Majority of styling is under the control of Weatherzone itself;
Module references a separate CSS file (see view) that controls majority of the layout.
CSS below is purely to either override Weatherzone widget, OR to override core
site CSS (most notably tables) to better match the Weatherzone design.
 */

.weather-zone {

    margin-top: 50px;
    margin-bottom: 50px;

    table {
        border: none;

        th,
        td {
            border-width: 0;
            border: none;
        }
    }

    img {
        width: auto;
    }

    .table-responsive.weather-forecast {
        width: 97%;
    }

    .weather-widget .table th,
    .weather-widget .table td {
        padding: 4px;
        vertical-align: middle;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}
