$maxContentWidth: 1440px;

/*
Common config variables
These are deliberately on the global scope as they
are shared between _global-header and _global-nav
 */

$globalHeader: (
    "moduleHeight": 80px,
    "moduleHeight_lg": 125px,
);

$notificationBanner: (
    "moduleHeight": 120px,
);