$colours: (
        //Primary
        "p-1": #006A71,
        "p-2": #009EDE,
        "p-3": #97DAF8,

        //Secondary
        "s-1": #0097A0,
        "s-2": #A15A95,
        "s-3": #004F59,

        //Neutral
        "n-1": #000000,
        "n-2": #111111,
        "n-3": #333333,
        "n-4": #666666,
        "n-5": #999999,
        "n-6": #AAAAAA,
        "n-7": #CCCCCC,
        "n-8": #DDDDDD,
        "n-9": #F5F5F5,
        "n-10": #FFFFFF,
);
