table {
    $tableBorderColour: map_get($colours, 'n-3');
    $tableBorderWidth: 1px;
    $tableCellPadding: 10px;

    width: 100%;
    border: solid 1px $tableBorderColour;

    th {
        padding: $tableCellPadding;
        border-style: solid;
        border-color: $tableBorderColour;
        border-width: $tableBorderWidth;
    }
    td {
        @include fontSize(map_get($body, "fontSize_lg"));
        @include lineHeight(map_get($h4, "lineHeight_lg"));
        padding: $tableCellPadding;
        border-style: solid;
        border-color: $tableBorderColour;
        border-width: $tableBorderWidth;
    }

    thead {
        th {
            background-color: map_get($colours, 'n-7');
        }
    }
}