/**
Search
 */

.search {
    background-color: map_get($colours, 'p-1');
    width: 100%;
    padding: 30px $grid-gutter-width;

    .input-wrap {
        display: flex;
        position: relative;
    }

    input {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: none;
        outline: none;
        padding-left: 15px;
        padding-right: 40px;
    }

    button {
        position: absolute;
        background: none;
        border: none;
        top: 9px;
        right: 3px;
    }

    .icon {
        color: map_get($colours, 'p-1');
    }

    // 992 +
    @include media-breakpoint-up(lg) {
        width: 230px;
        padding: 0 0 0 $grid-gutter-width;
        background-color: transparent;
    }
}


.searchresults {
    padding: 30px 0;

    // 992 +
    @include media-breakpoint-up(lg) {
        padding: 0;
        min-height: 500px;
    }
}
