/*
Shared, reusuable classes for
Swiper.js controls
 */

.tabbed-pagination {
    text-align: center;

    .swiper-pagination-bullet {
        width: 40px;
        height: 2px;
        background-color: #CCCCCC;
        border-radius: 0;
        opacity: 1;
        margin: 0 2.5px;
    }

    .swiper-pagination-bullet-active {
        background-color: map_get($colours, "p-1");
    }
}
