/**
Documentation
 */

.promo-tile--large {
    /**
    Module specific variables
     */
    $textColour: map_get($colours, 'n-2');
    $textColourHover: lighten(map_get($colours, 'p-1'), 0%);

    /**
    Default, Mobile first styles
     */
    .content-block {
        padding: 20px 0 35px 0;
    }

    .img-block {
        position: relative;
    }

    a {
        @include fontSize(26px);
        @include lineHeight(36px);
        color: $textColour;
        text-decoration: none;
        font-weight: 700;

        &:hover {
            color: $textColourHover;
        }

        .arrow-circle {
            display: none;
        }
    }

    //992 +
    @include media-breakpoint-up(lg) {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 45%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
            opacity: 0.5;
            z-index: 5;
        }

        .content-block {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            padding: 0 50px 25px 50px;
        }

        a {
            @include animateCustom(#{'color'}, 250ms);
            @include fontSize(36px);
            @include lineHeight(46px);
            color: white;
            display: flex;
            width: 100%;

            .arrow-circle {
                @include animateCustom(#{'color, border, transform'}, 200ms);
                display: flex;
                width: 60px;
                height: 60px;
                padding: 14px;
                border: solid 2px white;
                border-radius: 50%;
                margin-left: auto;

                .icon {
                    @include animateCustom(#{'color'}, 200ms);
                    width: 28px;
                    height: 28px;
                    color: white;
                }
            }

            &:hover {
                color: $textColourHover;
                .arrow-circle {
                    transform: translateX(5px);
                    border: solid 2px $textColourHover;

                    .icon {
                        color: $textColourHover;
                    }
                }
            }
        }
    }
}
