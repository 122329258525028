/**
Placeable form module
Note: this file mostly contains styles specific to the module itself,
primarily structural.
Any form element + input styling is found in:
/ui/_forms.scss
 */

.form-module {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 60px;
    margin-bottom: 60px;

    h4.umbraco-forms-caption {
        @include fontSize(20px);
        @include lineHeight(30px);
        margin-bottom: 20px;
        font-weight: 600;
    }

    /*
    Default column structure + grid fixex
     */
    .row-fluid {
        @include make-row();
    }

    .umbraco-forms-field {
        margin-bottom: 15px;
    }

    .umbraco-forms-navigation {
        padding: 30px 0;
    }

    /*
    Minor corrections to radio
     */
    .singlechoice,
    .multiplechoice {
        label {
            margin-bottom: 10px;
        }
    }

    //992 +
    @include media-breakpoint-up(lg) {

        margin-top: 80px;
        margin-bottom: 80px;

        h4.umbraco-forms-caption {
            @include fontSize(24px);
            @include lineHeight(34px);
            margin-bottom: 35px;
        }
    }
}
