/**
Documentation
 */

.promo-tile-row--medium {
    /**
    Default, Mobile first styles
     */
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: map_get($colours, 'n-9');

    .promo-tile--medium {
        height: 100%;
    }

    .title-content {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        a {
            margin-left: auto;
        }
    }

    .row-title {
        @include fontSize(30px);
        @include lineHeight(38px);
        color: map_get($colours, 'p-1');
        font-weight: 700;
        margin: 0;
    }

    .swiper-container {
        padding: 0 $grid-gutter-width / 2;
        padding-bottom: 70px;
    }

    .tabbed-pagination {
        position: absolute;
    }


    //992 +
    @include media-breakpoint-up(lg) {
        .title-content {
            margin-bottom: 60px;
        }

        .row-title {
            @include fontSize(46px);
            @include lineHeight(56px);
        }

        .tabbed-pagination {
            display: none;
        }
    }
}
