/*
Handy reset to clear browser default styles
for <button> elements
*/
@mixin btnReset(){
    background: none;
    border: none;
    margin: 0;
    padding: 0;

    &:focus,
    &:active {
        outline: none;
    }
}

@mixin btnDisabled(){
    cursor: pointer;
    pointer-events: none;
}

/*
Common, re-usable mixins for button styles
Application of these should be managed via /ui/_buttons.scss
 */

@mixin btnPill(
    $display: inline-block,
    $font: $fontPrimary,
    $fontSize: 18px,
    $lineHeight: 27px,
    $colour_default: black,
    $colour_hover: white,
    $textDecoration: none,

    $padding: 15px 90px,

    $borderRadius: 50px,
    $borderWidth: 2px,
    $borderColour_default: map_get($colours, "p-1"),
    $borderColour_hover: map_get($colours, "p-1"),

    $bgColour: white,
    $bgColour_hover: map_get($colours, "p-1"),

    $animateHover: true,
    $animationDuration: $defaultAnimationTime
){
    @include fontSize($fontSize);
    @include lineHeight($lineHeight);
    display: $display;
    padding: $padding;
    font-family: $font;
    color: $colour_default;
    border: solid $borderWidth $borderColour_default;
    border-radius: $borderRadius;
    background-color: $bgColour;
    text-decoration: $textDecoration;
    font-weight: 700;

    @if ($animateHover == true) {
        @include animateCustom(#{'border, color, background-color'}, $animationDuration);
    }

    &:hover {
        color: $colour_hover;
        border: solid $borderWidth $borderColour_hover;
        background-color: $bgColour_hover;
        text-decoration: $textDecoration;
    }
}

@mixin btnCircle(){
    @include fontSize(18px);
    @include lineHeight(18px);
    display: block;
    width: 34px;
    height: 34px;
    padding: 5px 0;
    border: solid 2px map_get($colours, 'p-1');
    color: map_get($colours, 'p-1');
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    font-weight: 700;

    .icon {
        width: 21px;
        height: 21px;
    }

    &:hover,
    &.current {
        background-color: map_get($colours, 'p-1');
        color: white;
    }

    &.disabled {
        pointer-events: none;
        color: map_get($colours, 'n-7');
        border: solid 2px map_get($colours, 'n-7');

        &:hover {
            background-color: transparent;
            color: map_get($colours, 'n-7');
        }
    }
}