/**
Full width video player module
 */

.video-module {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 60px;
    margin-bottom: 60px;
    width: 100%;
    position: relative;

    .inner-wrap {
        position: relative;
    }

    .img-layer {
        @include animateCustom(#{'opacity'}, $defaultAnimationTime);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 5;

        img {
            width: 100%;
        }
    }

    .video-layer {
        @include aspectRatio(16,9);
        overflow: hidden;
        z-index: 1;

        > div {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }

    .btn-play-video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
    }


    /*
    Content hidden
     */
    &.content-hidden {
        .img-layer,
        .btn-play-video {
            opacity: 0;
            pointer-events: none;
        }
    }


    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 60px;
        margin-bottom: 70px;
        overflow: hidden;
    }
}
