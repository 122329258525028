.btn-default {
    @include btnPill();
}

.btn-alt {
    @include btnPill(
        $colour_default: white,
        $colour_hover: white,
        $bgColour: black
    );
}

.btn-transparent {
    @include btnPill(
        $colour_default: white,
        $colour_hover: black,
        $bgColour: transparent,
        $bgColour_hover: white,
        $borderColour_default: white,
        $borderColour_hover: white
    );
}

.btn-default--small {
    @include btnPill(
        $fontSize: 16px,
        $lineHeight: 24px,
        $padding: 10px 48px
    );
}

.btn-alt--small {
    @include btnPill(
        $fontSize: 16px,
        $lineHeight: 24px,
        $padding: 14px 45px,
        $colour_default: white,
        $colour_hover: white,
        $bgColour: black
    );
}

.btn-icon {
    @include btnReset();
    @include animateCustom(#{'background-color, color'}, $defaultAnimationTime);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: map_get($colours, "n-1");
    color: white;
    border: solid 2px map_get($colours, "n-1");

    .icon {
        width: 22px;
        height: 22px;
        position: relative;
        top: -1px;
    }

    &:hover {
        background-color: transparent;
        color: map_get($colours, "n-1");
    }

    &:active {
        transform: scale(0.9);
        background-color: map_get($colours, "n-4");
    }

    &.btn-prev:active {
        transform: translateX(-3px);
    }

    &.btn-next:active {
        transform: translateX(3px);
    }
}


/*
TODO:
- These classes could  be mixins
- Refactor; CSS is a little excessive; could be smarter
 */
.btn-icon-text-prev,
.btn-icon-text-next {
    @include btnReset();
    @include animateCustom(#{'transform'}, $defaultAnimationTime);
    width: 46px;
    height: 34px;
    position: relative;
    color: map_get($colours, "n-1");

    .txt {
        @include fontSize(14px);
        @include lineHeight(21px);
        font-weight: 600;
    }

    .icon {
        @include animateCustom(#{'transform, color'}, $defaultAnimationTime);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 46px;
        height: 8px;
    }

    &:hover {
        color: map_get($colours, "n-2");
    }

    /*
    Disabled state, using swiper classes here also
     */
    &.disabled,
    &.swiper-button-disabled {
        cursor: default;
        opacity: 0.5;
    }

    &.disabled:hover,
    &.swiper-button-disabled:hover {
        color: map_get($colours, "n-1");
    }

}

.btn-icon-text-prev {
    padding-right: 4px;
    .txt {
        margin-left: auto;
    }

    &:hover {
        .icon {
            transform: translateX(-5px);
        }
    }

    &.disabled:hover,
    &.swiper-button-disabled:hover {
        .icon { transform: none;}
    }
}
.btn-icon-text-next {
    padding-left: 4px;

    &:hover {
        .icon {
            transform: translateX(5px);
        }
    }

    &.disabled:hover,
    &.swiper-button-disabled:hover {
        .icon { transform: none;}
    }
}

.btn-play-video {
    @include animateCustom(#{'background-color, opacity'}, $defaultAnimationTime);
    @include btnReset();
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background-color: black;

    span.arrow {
        display: block;
        margin: auto;
        position: relative;
        left: 2px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7.5px 0 7.5px 11px;
        border-color: transparent transparent transparent white;
    }

    &:hover {
        background-color: rgba(black, 0.5);
    }


    //992 +
    @include media-breakpoint-up(lg) {
        width: 132px;
        height: 132px;

        span.arrow {
            left: 4px;
            border-width: 13px 0 13px 20px;
            border-color: transparent transparent transparent white;
        }
    }
}

