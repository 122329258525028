/**
Pagination - used primarily for News listing / landing page template
 */

.pagination {

    /**
    Default, Mobile first styles
     */
    width: 100%;

    ul {
        display: flex;
        margin-left: 5px;
        margin-right: 5px;
    }

    li {
        margin-left: 5px;
        margin-right: 5px;
    }

    a,
    .disabled {
        @include btnCircle();
    }
}
