.facebook-feed {
    img {
        height: 210px;
        object-fit: cover;
    }

    .post-date {
        color: map_get($colours, 'n-4');
        margin-bottom: 20px;
    }

    .promo-tile--small{
        padding-bottom: 30px;
    }
}
