/**
Documentation
 */

.content-detail {
    background-color: red; //TODO -- check if this module is actually used
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin: 60px auto 60px auto;
    color: map_get($colours, 'n-1');

    img {
        margin-bottom: 60px;
    }

    /*
    Child content
     */
    .title {
        @include fontSize(20px);
        @include lineHeight(30px);
        margin-bottom: 24px;
        font-weight: 600;

    }

    .sub-header {
        @include fontSize(24px);
        @include lineHeight(34px);
        margin-bottom: 40px;
    }

    .content-block {
        margin-bottom: 40px;

        a, p {
            @include fontSize(16px);
            @include lineHeight(24px);
        }

        ul {
            margin: 0;
            padding-left: 20px;
        }

        li {
            @include fontSize(16px);
            @include lineHeight(24px);
            margin-bottom: 25px;
        }
    }


    //992 +
    @include media-breakpoint-up(lg) {

        /*
        Child content
         */
        .title {
            @include fontSize(24px);
            @include lineHeight(34px);
            margin-bottom: 20px;
        }

        .sub-header {
            @include fontSize(30px);
            @include lineHeight(40px);
            margin-bottom: 30px;
        }
    }
}
