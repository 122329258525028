.page-intro-text {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin: 60px 0 40px 0;

    h2 {
        @include fontSize(30px);
        @include lineHeight(38px);
        color: map_get($colours, 'p-1');
        font-weight: 700;
        margin-bottom: 40px;
    }

    p {
        @include fontSize(24px);
        @include lineHeight(34px);
        color: map_get($colours, 'n-4');
        margin-bottom: 0;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin: 80px 0 60px 0;

        h2 {
            @include fontSize(46px);
            @include lineHeight(56px);
            margin-bottom: 30px;
        }

        p {
            @include fontSize(26px);
            @include lineHeight(36px);
        }
    }
}
