/**
 * All RFDS theme styles need be contained within the following class.
 */
.umbraco-forms-page,
.umbraco-forms-essentialwater,
.umbraco-forms-form {

    //Label variables
    $labelFontSize: 16px;
    $labelLineHeight: 24px;

    //Input variables
    $inputFontSize: 16px;
    $inputLineHeight: 25px;
    $inputBackgroundColor: white;
    $inputBorderColour: #ccc;
    $inputBorderColour--active: #111;
    $inputBorderWidth: 1px;
    $inputBorderRadius: 4px;
    $inputPadding_x: 16px;
    $inputPadding_y: 12px;

    //Select variables
    $selectHeight: 50px;

    //Checkbox variable
    $checkBoxWidth: 24px;
    $checkBoxBackgroundColour--default: transparent;
    $checkBoxBackgroundColour--hover: transparent;
    $checkBoxBackgroundColour--checked: transparent;

    $checkBoxBorderWidth: 1px;
    $checkBoxBorderColour--default: #ccc;
    $checkBoxBorderColour--hover: #ccc;
    $checkBoxBorderColour--checked: #ccc;

    $checkBoxtickColour: map_get($colours, 'p-1');
    $checkBoxtickPosition_x: 7px;
    $checkBoxtickPosition_y: 10px;

    //Radio variables
    $radioWidth: 25px;
    $radioBackgroundColour--default: transparent;
    $radioBackgroundColour--hover: transparent;
    $radioBackgroundColour--checked: transparent;

    $radioBorderWidth: 1px;
    $radioBorderRadius: 50%;
    $radioBorderColour--default: #ccc;
    $radioBorderColour--hover: #111;
    $radioBorderColour--checked: #111;

    $radioTickColour: #111;
    $radioTickPosition_x: 7px;
    $radioTickPosition_y: 11px;

    //Validation variables
    $errorMsgFontSize: 12px;
    $errorMsgColor: #b80000;

    /*
    Shared label
     */
    label.umbraco-forms-label {
        @include fontSize($labelFontSize);
        @include lineHeight($labelLineHeight);
        font-weight: 700;
        margin-bottom: 2px;
    }

    /*
    Text input and text area fields
     */
    input.text {
        width: 100%;
        max-width: 100% !important;
        padding: $inputPadding_y $inputPadding_x;
        background: $inputBackgroundColor;
        font-size: $inputFontSize;
        line-height: $inputLineHeight;
        border: solid $inputBorderWidth $inputBorderColour;
        border-radius: $inputBorderRadius;

        &:focus,
        &:active {
            border-color: $inputBorderColour--active;
            outline: none;
        }
    }

    textarea {
        width: 100%;
        max-width: 100% !important;
        height: 150px;
        padding: $inputPadding_y $inputPadding_x;
        background: $inputBackgroundColor;
        font-size: $inputFontSize;
        line-height: $inputLineHeight;
        border: solid $inputBorderWidth $inputBorderColour;
        border-radius: $inputBorderRadius;

        &:focus,
        &:active {
            border-color: $inputBorderColour--active;
            outline: none;
        }
    }

    /*
    Select fields
     */
    select {
        width: 100%;
        max-width: 100%;
        height: $selectHeight;
        padding: $inputPadding_y $inputPadding_x;
        font-size: $inputFontSize;
        line-height: $inputLineHeight;
        border: solid $inputBorderWidth $inputBorderColour;
        border-radius: $inputBorderRadius;

        &:focus,
        &:active {
            border-color: $inputBorderColour--active;
            outline: none;
        }
    }
    /*
    File uploades
     */
    input[type="file"] {
        width: 100%;
    }
    /*
    Checkboxes
     */
    .checkbox,
    .checkboxlist {
        input[type='checkbox'] {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it

            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
            }
            // Box.
            & + label:before {
                content: '';
                display: inline-block;
                position: relative;
                top: -2px;
                width: $checkBoxWidth;
                height: $checkBoxWidth;
                margin-right: 10px;
                vertical-align: middle;
                border: solid $checkBoxBorderWidth $checkBoxBorderColour--default;
                background: $checkBoxBackgroundColour--default;
                border-radius: 3px;
            }
            // Box hover + focus
            &:hover + label:before,
            &:focus + label:before {
                background: $checkBoxBackgroundColour--hover;
                border-color: $checkBoxBorderColour--hover;
            }
            // Box checked
            &:checked + label:before {
                background: $checkBoxBackgroundColour--checked;
                border-color: $checkBoxBorderColour--checked;
            }
            // Disabled state label.
            &:disabled + label {
                color: #b8b8b8;
                cursor: auto;
            }
            // Disabled box.
            &:disabled + label:before {
                box-shadow: none;
                background: #ddd;
            }
            // Checkmark. Could be replaced with an image
            &:checked + label:after {
                content: '';
                position: absolute;
                left: $checkBoxtickPosition_x;
                top: $checkBoxtickPosition_y;
                background: $checkBoxtickColour;
                width: 2px;
                height: 2px;
                box-shadow: 2px 0 0 $checkBoxtickColour, 4px 0 0 $checkBoxtickColour, 4px -2px 0 $checkBoxtickColour, 4px -4px 0 $checkBoxtickColour, 4px -6px 0 $checkBoxtickColour, 4px -8px 0 $checkBoxtickColour;
                transform: rotate(35deg);
            }
        }
    }

    .checkbox {
        position: relative;

        label {
            margin-left: 35px;
        }

        .check {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: $checkBoxWidth;
            height: $checkBoxWidth;
            margin-right: 10px;
            vertical-align: middle;
            border: solid $checkBoxBorderWidth $checkBoxBorderColour--default;
            background: $checkBoxBackgroundColour--default;
        }

        &:hover,
        &:focus {

            .check {
                background: $checkBoxBackgroundColour--hover;
                border-color: $checkBoxBorderColour--hover;
            }
        }

        input[type="checkbox"] {
            opacity: 0;
            // Box checked
            &:checked ~ .checkbox-ticked {
                position: absolute;
                left: $checkBoxtickPosition_x;
                top: 12px;
                background: $checkBoxtickColour;
                width: 2px;
                height: 2px;
                box-shadow: 2px 0 0 $checkBoxtickColour, 4px 0 0 $checkBoxtickColour, 4px -2px 0 $checkBoxtickColour, 4px -4px 0 $checkBoxtickColour, 4px -6px 0 $checkBoxtickColour, 4px -8px 0 $checkBoxtickColour;
                transform: rotate(35deg);
            }

            &:checked + .check {
                background: $checkBoxBackgroundColour--checked;
                border-color: $checkBoxBorderColour--checked;
            }
        }

        &.disabled {
            label {
                color: #b8b8b8;
                cursor: auto;
                pointer-events: none;
            }

            span.check, span.checkbox-ticked {
                pointer-events: none;
            }

            span.check, span.checkbox-ticked, input[type="checkbox"], input[type="checkbox"]:checked + .check {
                box-shadow: none;
                background: #ddd;
            }
        }
    }

    /*
    Radio
     */
    input[type='radio'] {
        position: absolute; // take it out of document flow
        opacity: 0; // hide it
        & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            display: inline-block;
        }
        // Box.
        & + label:before {
            content: '';
            display: inline-block;
            width: $radioWidth;
            height: $radioWidth;
            position: relative;
            top: -2px;
            margin-right: 10px;
            vertical-align: middle;
            border: solid $radioBorderWidth $radioBorderColour--default;
            border-radius: $radioBorderRadius;
            background: $radioBackgroundColour--default;
        }
        // Box hover + focus
        &:hover + label:before,
        &:focus + label:before {
            background: $radioBackgroundColour--hover;
            border-color: $radioBorderColour--hover;
        }
        // Box checked
        &:checked + label:before {
            background: $radioBackgroundColour--checked;
            border-color: $radioBorderColour--checked;
        }
        // Disabled state label.
        &:disabled + label {
            color: #b8b8b8;
            cursor: auto;
        }
        // Disabled box.
        &:disabled + label:before {
            box-shadow: none;
            background: #ddd;
        }
        // Checkmark. Could be replaced with an image
        &:checked + label:after {
            content: '';
            position: absolute;
            left: $radioTickPosition_x;
            top: $radioTickPosition_y;
            background: $radioTickColour;
            width: 2px;
            height: 2px;
            box-shadow: 2px 0 0 $radioTickColour, 4px 0 0 $radioTickColour, 4px -2px 0 $radioTickColour, 4px -4px 0 $radioTickColour, 4px -6px 0 $radioTickColour, 4px -8px 0 $radioTickColour;
            transform: rotate(35deg);
        }
    }


    /*
    Submit
     */
    input[type='submit'] {
        @include btnPill(
            $fontSize: 16px,
            $lineHeight: 24px,
            $padding: 10px 68px
        );
    }

    /*
    Validation & error states
     */

    .field-validation-error {
        font-size: $errorMsgFontSize;
        font-weight: bold;
        color: $errorMsgColor !important;
    }

    /*
    General layout + spacing
     */
    .umbraco-forms-fieldset {
        margin: 0;
        padding: 0;
    }

    .umbraco-forms-field {
        margin: 35px 0;

        &:first-child {
            margin: 0;
        }
    }

    .umbraco-forms-field-wrapper .checkboxlist {
        padding: 0;
    }

    .umbraco-forms-tooltip,
    .help-block {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 0.8rem;
    }

    span.checkboxlist,
    .radiobuttonlist,
    span.checkbox {
        float: none;

        label {
            float: none;
        }
    }
    //Checkbox labels
    .checkboxlist label {
        display: inline-block;
    }
}