/**
Documentation
 */

#global-nav {
    /**
    Module specific variables
     */
    $moduleHeight: auto;
    $moduleHeight_lg: 40px;
    $moduleBgColor: white;
    $moduleBgColor_lg: transparent;

    //Subnav specific
    $subNav: (
            "width": auto,
            "width_lg": 230px,  //Cannot be 'auto'
            "center": false,
            "bgColour": white,
            "showOnHover": true,
            "animateSpeed": 250ms,
            "padding": 0,
            "padding_lg": 10px 0
    );

    /**
    Default, Mobile first styles
     */
    @include animateCustom(#{'opacity'}, 300ms);
    display: block;
    width: 100vw;
    height: calc(100vh -  #{map_get($globalHeader, "moduleHeight")});
    position: fixed;
    left: 0;
    top: map_get($globalHeader, "moduleHeight");
    z-index: 5;
    background-color: $moduleBgColor;
    pointer-events: none; //when hidden / closed

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0 $grid-gutter-width;
    }


    /*
    Top level nav items
     */
    > ul > li {
        border-bottom: 1px solid rgba(#AAAAAA, 0.3);

        a, .text {
            @include fontSize(24px);
            @include lineHeight(70px);
            color: map_get($colours, 'n-2');
            font-weight: 700;
            @include animateCustom(#{'color'}, 300ms);
        }

        a {
            text-decoration: none;
        }
    }

    .btn-accordion-trigger {
        @include btnReset();
        padding: 20px 0;
        height: 70px;
        float: right;

        .icon {
            width: 20px;
            height: 20px;
        }

        .icon.active {
            display: none;
        }

        &.active {
            .icon.default {
                display: none;
            }

            .icon.active {
                display: block;
            }
        }
    }
    /*
    Default mobile subnav state
     */
    ul > li > ul {
        @include animateCustom(#{'height'}, map_get($subNav, "animateSpeed"));
        overflow: hidden;

        li {
            border-bottom: none;

            &:last-child {
                padding-bottom: 30px;
            }
        }

        a {
            @include fontSize(18px);
            @include lineHeight(36px);
            font-weight: 400;
            text-decoration: none;
        }
    }

    /*
    Default mobile state
    */
    opacity: 0;

    /*
    Open mobile state
     */
    &.open {
        opacity: 1;
        pointer-events: all;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        height: auto;
        width: auto;
        left: auto;
        top: auto;
        opacity: 1;
        padding: 0;
        background-color: $moduleBgColor_lg;
        pointer-events: all;


        .btn-accordion-trigger {
            display: none;
        }

        /*
        Search wrapper
         */
        .search {
            order: 2;
        }

        /*
        First level list
         */
        > ul {
            order: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            > li {
                display: block;
                position: relative;
                padding: 15px 0 15px 22px;
                border-bottom: none;

                > a {
                    @include fontSize(16px);
                    @include lineHeight(24px);
                    color: white;

                    &:hover {
                        color: lighten(map_get($colours, 's-1'), 10%);
                    }
                }

                .text {
                    @include fontSize(16px);
                    @include lineHeight(24px);
                    color: white;
                }

                &.has-subnav {
                    padding-right: 24px;
                    //Triangle
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 26px;
                        right: 0;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 6px 5px 0 5px;
                        border-color: #fff transparent transparent transparent;
                    }
                }
            }
        }



        /*
        Default subNav states
         */
        ul > li > ul {
            @include animateCustom(#{'opacity, transform'}, map_get($subNav, "animateSpeed"));
            position: absolute;
            width: map_get($subNav, "width_lg");
            height: auto;
            top: 55px;
            padding: map_get($subNav, "padding_lg");
            opacity: 0;
            background-color: map_get($subNav, "bgColour");
            pointer-events: none;
            right: -5px;

            li:last-child {
                padding-bottom: 0;
            }

            a {
                @include fontSize(14px);
                @include lineHeight(36px);
                transition: none;
                display: block;
                padding: 0 20px;
                color: map_get($colours, 'n-1');
                text-decoration: none;

                &:hover {
                    color: map_get($colours, 'n-10');
                    background-color: map_get($colours, 's-1');
                }
            }
        }


        /*
        Hover > show dropdown subnav
         */
        @if map_get($subNav, "showOnHover") == true {
            ul {
                > li {
                    &:hover {
                        > ul {
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }
    }
}
