$fontPrimary: 'Oxygen', sans-serif;
$browser-context: 16px;
$body: (
        "fontSize": 16px,
        "lineHeight": 24px,
        "fontSize_lg": 16px,
        "lineHeight_lg": 24px,
);

$h1: (
        "fontSize": 30px,
        "lineHeight": 38px,
        "fontSize_lg": 46px,
        "lineHeight_lg": 56px,
);

$h2: (
        "fontSize": 26px,
        "lineHeight": 36px,
        "fontSize_lg": 36px,
        "lineHeight_lg": 46px,
);

$h3: (
        "fontSize": 24px,
        "lineHeight": 34px,
        "fontSize_lg": 30px,
        "lineHeight_lg": 40px,
);

$h4: (
        "fontSize": 20px,
        "lineHeight": 30px,
        "fontSize_lg": 24px,
        "lineHeight_lg": 34px,
);

$h5: (
        "fontSize": 20px,
        "lineHeight": 30px,
        "fontSize_lg": 24px,
        "lineHeight_lg": 34px,
);

$h6: (
        "fontSize": 20px,
        "lineHeight": 30px,
        "fontSize_lg": 18px,
        "lineHeight_lg": 27px,
)