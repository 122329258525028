.quick-enquiry-form {


    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 60px;
    margin-bottom: 80px;

    .module-title {
        @include fontSize(30px);
        @include lineHeight(38px);
        color: map_get($colours, 'p-1');
        margin-bottom: 30px;
        font-weight: 700;
    }

    .module-desc {
        @include fontSize(18px);
        @include lineHeight(27px);
        color: map_get($colours, 'n-4');
        margin-bottom: 60px;
    }

    .general-contact-info {
        padding: 40px 40px 20px 40px;
        background-color: map_get($colours, 'n-9');
        margin-bottom: 55px;
    }

    .contact-method {
        p {
            @include fontSize(16px);
            @include lineHeight(24px);
            color: map_get($colours, 'n-4');
            margin-bottom: 20px;
        }

        .title {
            @include fontSize(18px);
            @include lineHeight(27px);
            font-weight: 700;
            color: map_get($colours, 'n-2');
            margin-bottom: 10px;
        }

        a {
            @include fontSize(16px);
            @include lineHeight(24px);
            display: inline-block;
            font-weight: 700;
            color: map_get($colours, 'p-1');
            text-decoration: none;
            margin-bottom: 20px;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .umbraco-forms-form {
        width: 100%;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 80px;
        margin-bottom: 120px;

        .module-title {
            @include fontSize(46px);
            @include lineHeight(56px);
            margin-bottom: 20px;
        }

        .module-desc {
            margin-bottom: 53px;
        }

        .general-contact-info {
            margin-right: 15px;
            margin-bottom: 0;
        }
    }
}
