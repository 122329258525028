$iconSize: 24px;

$iconSize_sm: $iconSize / 2;
$iconSize_lg: $iconSize * 2;
$iconSize_xl: $iconSize * 3;

.icon {
    width: $iconSize;
    height: $iconSize;

    &.size--sm {
        width: $iconSize_sm;
        height: $iconSize_sm;
    }

    &.size--lg {
        width: $iconSize;
        height: $iconSize;
    }

    &.size--xl {
        width: $iconSize_xl;
        height: $iconSize_xl;
    }

    &.icon-instagram{
        height: 27px;
    }
}