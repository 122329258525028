.image-header {
    width: 100%;
    max-height: 100vh;
    position: relative;
    color: white;
    overflow: hidden;

    .img-layer {
        width: 100%;
        height: 100%;

        picture,
        img {
            display: block;
            height: 100%;
        }
    }

    .content-layer {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        padding: 0 $grid-gutter-width;
        padding-bottom: 50px;
    }

    h1 {
        @include fontSize(30px);
        @include lineHeight(38px);
        font-weight: 700;
        margin-bottom: 30px;
    }

    a {
        @include fontSize(16px);
        @include lineHeight(24px);
        padding: 11px 60px 10px 60px;

        &:hover {
            color: map_get($colours, 'p-2');
        }
    }


    //Medium / reduced height variant
    &.md {
        max-height: 40vh;
    }


    //992 +
    @include media-breakpoint-up(lg) {
        max-height: 610px;
        margin-bottom: 60px;

        img {
            object-fit: cover;
        }

        .content-layer {
            padding: 0 0;
            padding-bottom: 60px;
        }

        h1 {
            @include fontSize(46px);
            @include lineHeight(56px);
            margin-bottom: 0;
        }

        a {
            margin-left: auto;
        }

        //Medium / reduced height variant
        &.md {
            max-height: 350px;
        }
    }
}
