/**
Documentation
 */

.promo-tile--medium {
    /**
    Module specific variables
     */
    $textColour: $black;
    $textColourHover: $link-hover-color;

    /**
    Default, Mobile first styles
     */
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.07);

    //a {
    //    height: 100%;
    //    display: flex;
    //    flex-direction: column;
    //    color: $textColour;
    //    text-decoration: none;
    //
    //    &:hover {
    //        color: $textColourHover;
    //    }
    //}

    .content-block {
        display: flex;
        flex-direction: column;
        padding: 30px 30px 40px 30px;
        background: white;
        flex-grow: 1;
    }

    .title {
        @include fontSize(24px);
        @include lineHeight(34px);
        color: map_get($colours, 'n-2');
        margin-bottom: 10px;
        font-weight: 700;
    }

    .desc {
        @include fontSize(16px);
        @include lineHeight(24px);
        color: map_get($colours, 'n-3');
        margin-bottom: 20px;
    }

    a {
        @include fontSize(16px);
        @include lineHeight(24px);
    }

    //992 +
    @include media-breakpoint-up(lg) {
        .content-block {
            padding: 40px 35px 53px 40px;
        }

        .desc {
            @include fontSize(16px);
            @include lineHeight(24px);
        }

        //a {
        //    @include fontSize(16px);
        //    @include lineHeight(24px);
        //}
    }

}
