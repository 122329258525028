/**
Documentation
 */

#global-footer {
    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    background-color: map_get($colours, "p-1");
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    color: white;

    .site-logo {
        width: 157px;
        height: 79px;
        margin-bottom: 40px;
    }

    .col-title {
        @include fontSize(18px);
        @include lineHeight(27px);
        font-weight: 700;
        margin-bottom: 10px;

        &.social {
            margin-bottom: 18px;
        }

        .social-link {
            position: relative;
            top: -2px;
            margin-left: 17px;
        }
    }

    ul {
        margin-bottom: 30px;
    }

    li,
    a {
        @include fontSize(16px);
        @include lineHeight(24px);
    }

    a {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .terms {
        @include fontSize(14px);
        @include lineHeight(21px);
        color: map_get($colours, 'n-6');

        a {
            color: map_get($colours, 'n-6');
            font-size: inherit;
            line-height: inherit;
        }
    }

    .site-credit {
        @include fontSize(14px);
        @include lineHeight(21px);
        margin-top: 0;
        color: map_get($colours, 'n-6');

        a {
            color: map_get($colours, 'n-6');
            font-size: inherit;
            line-height: inherit;
        }
    }

    .social-and-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    //992 +
    @include media-breakpoint-up(lg) {
        padding-top: 38px;
        padding-bottom: 38px;
        text-align: left;

        .site-logo {
            margin-bottom: 0;
        }

        .site-credit {
            margin-top: 30px;
        }

        .social-and-copyright {
            align-items: flex-end;
        }
    }
}
