/**
Documentation
 */

.pay-my-bill-form {
    /**
    Default, Mobile first styles
     */
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: map_get($colours, 'n-9');

    .form-wrapper {
        display: flex;
        justify-content: space-around;
    }
    
    form {
        background: white;
        padding: 40px;
    }
    
    .error-msg {
        display: none;
        color: #FF0000;
        margin-bottom: 20px;

        &.active {
            display: block;
        }
    }
    
    //992 +
    @include media-breakpoint-up(lg) {
        
    }
}
