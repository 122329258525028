/**
Full width image only module
 */

.image-module {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 60px;
    margin-bottom: 60px;

    //1200 +
    @include media-breakpoint-up(xl) {
        margin-top: 50px;
        margin-bottom: 80px;
    }
}
