aside {
    /**
    Default, Mobile first styles
     */
    padding: $grid-gutter-width 0;

    .promo-tile--large,
    .promo-tile--small,
    .promo-tile--medium {
        height: auto;
        margin-bottom: $grid-gutter-width / 2;
    }

    @include media-breakpoint-up(xs) {
    }

    //576 +
    @include media-breakpoint-up(sm) {
    }

    //768 +
    @include media-breakpoint-up(md) {
    }

    //992 +
    @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width * 2 0;
    }

    //1200 +
    @include media-breakpoint-up(xl) {
    }
}

