
.global-utility-nav {

    /**
    Default, Mobile first styles
     */

    @include media-breakpoint-down(lg) {
        .container-fluid {
            padding: 0;
        }
    }

    a {
        @include fontSize(18px);
        @include lineHeight(36px);
        color: map_get($colours, 'n-2');
        text-decoration: none;

        &:hover {
            color: map_get($colours, 'p-1');
            text-decoration: underline;
        }
    }

    //992 +
    @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: flex-end;

        ul {
            display: flex;
            margin: 0;
        }
        li {
            margin-left: 20px;
        }

        a {
            @include fontSize(14px);
            @include lineHeight(21px);
            display: flex;
            align-items: center;
            color: map_get($colours, 'n-10');
            text-decoration: none;

            .icon {
                width: 17px;
                height: 17px;
                margin-right: 13px;
            }

            &:hover {
                color: map_get($colours, 'n-10');
                text-decoration: none;

                .txt {
                    text-decoration: underline;
                }
            }
        }
    }
}
