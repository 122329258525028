/*
Reusable mixins specific to structure and structural overides,
eg, paddings, margins, general module width etc
 */

@mixin mobileContentPadding(
    $paddingLeft: $grid-gutter-width / 2,
    $paddingRight: $grid-gutter-width / 2
    ){
    @include media-breakpoint-down(md){
        padding-left: $paddingLeft;
        padding-right: $paddingRight;
    }
}