.promo-tile--small {
    display: flex;
    flex-direction: column;

    .content-block {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        flex-grow: 1;
    }

    .title {
        @include fontSize(24px);
        @include lineHeight(34px);
        font-weight: 700;
        color: map_get($colours, 'n-2');
        margin-bottom: 10px;
    }

    .desc {
        @include fontSize(16px);
        @include lineHeight(24px);
        font-weight: 400;
        color: map_get($colours, 'n-4');
        margin-bottom: 20px;
    }

    a {
        @include fontSize(16px);
        @include lineHeight(24px);
        margin-top: auto;
    }
}
