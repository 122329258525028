/**
Documentation
 */

#notification-banner {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    width: 100%;
    background-color: map_get($colours, 'p-3');
    color: map_get($colours, 'n-2');
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    display: none; //Hidden by default, based on cookie check
    height: map_get($notificationBanner, 'moduleHeight');//TODO -- discuss this with team

    .content-wrap {
        position: relative;
        padding-right: 20px;
    }

    .notification-title {
        @include fontSize(18px);
        @include lineHeight(27px);
        margin: 0;
        font-weight: 700;
    }

    .notification-text {
        @include fontSize(16px);
        @include lineHeight(24px);
        margin: 0;
    }

    .btn-close {
        @include btnReset();
        position: absolute;
        top: 8px;
        right: -16px;
        width: 30px;
        height: 30px;

        .icon {
            width: 28px;
            height: 28px;
            color: map_get($colours, 'n-2');;
        }
    }

    &.active {
        display: block;
    }


    //992 +
    @include media-breakpoint-up(lg) {
        position: relative;

        .btn-close {
            right: 0;
        }
    }
}
