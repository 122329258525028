$hamburger-padding-x           : 10px !default;
$hamburger-padding-y           : 10px !default;
$hamburger-layer-width         : 20px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : white !default;
$hamburger-layer-border-radius : 0px !default;
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;


@import '../../../../node_modules/hamburgers/_sass/hamburgers/hamburgers';