/**
Documentation
 */

#global-header {
    /**
    Module specific variables
     */
    $modulePaddingTop: 12px;
    $modulePaddingY_lg: 0;

    /**
    Default, Mobile first styles
     */
    @include animateCustom(#{'background-color, padding'}, $defaultAnimationTime);
    height: map_get($globalHeader, 'moduleHeight');
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: map_get($colours, 'p-1');
    padding: $modulePaddingTop 12px;

    h1 {
        margin: 0;
        padding: 0;

        a {
            position: relative;
        }
    }

    /*
    Menu controls
     */
    .btn-offcanvas {
        @include btnReset();
        width: 50px;
        height: 50px;
        position: relative;
        margin-left: auto;

        .icon {
            @include animateCustom(#{'opacity'}, $defaultAnimationTime);
            color: white;
            position: absolute;
            right: 0;
            top: 10px;
            height: 30px;
            width: 30px;
        }

        .icon.active { opacity: 0;}

        &.is-active {
            .icon.default { opacity: 0;}
            .icon.active { opacity: 1;}
        }
    }

    //Default mobile sizing
    .site-logo {
        width: 112px;
        height: 56px;
    }

    /*
    Utility nav containers
     */
    .utility-nav-wrap.sm {
        padding-top: 30px;
    }

    .utility-nav-wrap.lg {
        display: none;
    }

    //992 +
    @include media-breakpoint-up(lg) {
        width: 100%;
        height: map_get($globalHeader, 'moduleHeight_lg');
        position: relative;
        padding: 0 0;

        .site-logo {
            width: 138px;
            height: 69px;
        }

        .btn-offcanvas {
            display: none;
        }

        .primary-nav-row {
            display: flex;
            align-content: center;
            height: 90px;
        }

        /*
        Utility nav containers
         */
        .utility-nav-wrap.sm {
            display: none;
        }
        .utility-nav-wrap.lg {
            height: 35px;
            display: flex;
            background-color: rgba(black, 0.3);
        }
    }
}
