html,
body {
    font-family: $fontPrimary;
    font-size: $browser-context;
}

p,
a {
    @include fontSize(map_get($body, "fontSize"));
    @include lineHeight(map_get($body, "lineHeight"));

    //992 +
    @include media-breakpoint-up(lg) {
        @include fontSize(map_get($body, "fontSize_lg"));
        @include lineHeight(map_get($h4, "lineHeight_lg"));
    }
}


h1 {
    @include fontSize(map_get($h1, "fontSize"));
    @include lineHeight(map_get($h1, "lineHeight"));
    font-weight: 700;

    //992 +
    @include media-breakpoint-up(lg) {
        @include fontSize(map_get($h1, "fontSize_lg"));
        @include lineHeight(map_get($h1, "lineHeight_lg"));
    }
}

h2 {
    @include fontSize(map_get($h2, "fontSize"));
    @include lineHeight(map_get($h2, "lineHeight"));
    font-weight: 700;

    //992 +
    @include media-breakpoint-up(lg) {
        @include fontSize(map_get($h2, "fontSize_lg"));
        @include lineHeight(map_get($h2, "lineHeight_lg"));
    }
}

h3 {
    @include fontSize(map_get($h3, "fontSize"));
    @include lineHeight(map_get($h3, "lineHeight"));
    font-weight: 700;

    //992 +
    @include media-breakpoint-up(lg) {
        @include fontSize(map_get($h3, "fontSize_lg"));
        @include lineHeight(map_get($h3, "lineHeight_lg"));
    }
}

h4 {
    @include fontSize(map_get($h4, "fontSize"));
    @include lineHeight(map_get($h4, "lineHeight"));
    font-weight: 700;

    //992 +
    @include media-breakpoint-up(lg) {
        @include fontSize(map_get($h4, "fontSize_lg"));
        @include lineHeight(map_get($h4, "lineHeight_lg"));
    }
}

h5 {
    @include fontSize(map_get($h5, "fontSize"));
    @include lineHeight(map_get($h5, "lineHeight"));
    font-weight: 700;

    //992 +
    @include media-breakpoint-up(lg) {
        @include fontSize(map_get($h5, "fontSize_lg"));
        @include lineHeight(map_get($h5, "lineHeight_lg"));
    }
}

h6 {
    @include fontSize(map_get($h6, "fontSize"));
    @include lineHeight(map_get($h6, "lineHeight"));
    font-weight: 700;

    //992 +
    @include media-breakpoint-up(lg) {
        @include fontSize(map_get($h6, "fontSize_lg"));
        @include lineHeight(map_get($h6, "lineHeight_lg"));
    }
}

a {
    color: map_get($colours, 'p-1');
    text-decoration: underline;

    &:hover {
        color: lighten(map_get($colours, 'p-1'), 10%);
    }
}

/*
Class specific font family usage
 */
.font-primary {
    font-family: $fontPrimary;
}