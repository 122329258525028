/**
CSS overrides for StormTracker
Get reference from: https://www.essentialenergy.com.au/outages-and-faults/storm-tracker
 */

.stormtracker {
    position: relative;
    z-index: 0;
    margin-top: 50px;
    margin-bottom: 50px;

    .weatherzone-widget {
        display: flex;
        width: 100%;
        height: 576px !important;
    }

    img.weatherzone-logo {
        width: auto;
    }
    //992 +
    @include media-breakpoint-up(lg) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}
