/**
404 Page specific
 */

#template-404 {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    padding-top: 130px;
    padding-bottom: 100px;
    text-align: center;

    .logo-block {
        display: none;
    }

    h1 {
        @include fontSize(80px);
        @include lineHeight(80px);
        margin-bottom: 0;
        color: map_get($colours, 'p-1');
    }

    p {
        @include fontSize(22px);
        @include lineHeight(32px);
        margin-bottom: 30px;
    }

    @include media-breakpoint-up(xs) {
    }

    //576 +
    @include media-breakpoint-up(sm) {
    }

    //768 +
    @include media-breakpoint-up(md) {
    }

    //992 +
    @include media-breakpoint-up(lg) {
        padding-top: 200px;
        padding-bottom: 200px;

        h1 {
            @include fontSize(160px);
            @include lineHeight(160px);
        }

        p{
            @include fontSize(26px);
            @include lineHeight(36px);
            margin-bottom: 40px;
        }
    }

    //1200 +
    @include media-breakpoint-up(xl) {
    }
}
