/**
Rich Text Content moudle
 */

.content-module {

    /**
    Default, Mobile first styles
     */
    @include mobileContentPadding();
    margin-top: 40px;
    margin-bottom: 40px;

    img {
        width: auto;
        height: auto;
    }

    //1200 +
    @include media-breakpoint-up(xl) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
}
